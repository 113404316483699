import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx200DayMaChartTemplate from '@/templates/crypto/xxx-200-day-moving-average-chart';

export default function Ethereum200DayMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx200DayMaChartTemplate
      location={location}
      articleId={ArticleList.ETHEREUM_200_DAY_MOVING_AVERAGE_CHART}
      token={Token.ETHUSDT}
      coinFullName="Ethereum"
      coinAbbreviation="ETH"
    ></Xxx200DayMaChartTemplate>
  );
}
