import { getKLines } from '@/binance';
import MaTable from '@/components/ma-table';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { sma } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot from '@/components/charts/crypto-plot';

export default function Xxx200DayMaChartTemplate({
  articleId,
  location,
  token,
  coinFullName,
  coinAbbreviation,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [ma200, setMa200] = useState<number[]>();

  const [ma50Line, setMa50Line] = useState<ChartLine>({
    y: [],
    color: 'blue',
    strokeWidth: 1.5,
    title: 'MA50',
  });

  const [ma200Line, setMa200Line] = useState<ChartLine>({
    y: [],
    color: 'red',
    strokeWidth: 1.5,
    title: 'MA200',
  });

  const [ma255Line, setMa255Line] = useState<ChartLine>({
    y: [],
    color: 'green',
    strokeWidth: 1.5,
    title: 'MA200',
  });

  useEffect(() => {
    getKLines(token, '1d', 1000).then(function (df: DataFrame) {
      setDataFrame(df);
      const sma50 = sma(df.close, 50);
      const sma200 = sma(df.close, 200);
      const sma255 = sma(df.close, 255);
      setMa200(sma200);

      setMa50Line({
        y: sma50,
        color: 'blue',
        strokeWidth: 1.5,
        title: 'MA50',
      });

      setMa200Line({
        y: sma200,
        color: 'red',
        strokeWidth: 1.5,
        title: 'MA200',
      });

      setMa255Line({
        y: sma255,
        color: 'green',
        strokeWidth: 1.5,
        title: 'MA200',
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLowerCase(), 'ma200', 'chart']}
      articleId={articleId}
    >
      <section>
        <p>
          The {coinFullName} 200-day moving average chart is considered a key
          chart by crypto traders and crypto market analysts for determining
          overall long-term {coinAbbreviation} trend. The indicator appears as a
          line on a chart and meanders higher and lower along with the
          longer-term {coinAbbreviation} price.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[ma200Line]}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
        />
        <p>
          The {coinAbbreviation} 200-day MA seems, at times, to serve as an
          uncanny support level when the price is above the moving average or a
          resistance level when the price is below it.
        </p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={'200-Day Moving Average (' + coinAbbreviation + ')'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ma200}
          numberOfRows={10}
        />
        <h2>The {coinFullName} 200-Day MA Chart</h2>
        <p>
          The {coinAbbreviation} 200-day MA chart, which plots roughly 40 weeks
          of trading, is commonly used in crypto trading to determine the
          general {coinFullName} trend. As long as a {coinAbbreviation} price
          remains above the 200-day MA on the daily time frame, the coin is
          generally considered to be in an overall uptrend. One frequently used
          alternative to the 200-day MA is a 255-day moving average that
          represents the trading for the previous year.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[ma255Line]}
          title={coinAbbreviation + '/USDT MA255'}
          description={coinAbbreviation + '/USDT MA255'}
        />
        <p>
          As a very long-term moving average, the 200-day MA is often used in
          conjunction with other, shorter-term moving averages charts to show
          not only the {coinAbbreviation} trend but also to assess the strength
          of the trend as indicated by the separation between moving average
          lines. For example, comparing the 50-day MA and 200-day charts is
          relatively common.
        </p>
        <p>
          When {coinFullName} moving average lines converge, this sometimes
          indicates a lack of definitive crypto market momentum, whereas the
          increasing separation between shorter-term moving averages and
          longer-term moving averages typically indicates increasing trend
          strength and market momentum.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[ma50Line, ma200Line]}
          title={coinAbbreviation + '/USDT MA50 MA250'}
          description={coinAbbreviation + '/USDT MA50 MA250'}
        />
        <h2>Death Crosses and Golden Crosses - {coinFullName} MA200 Chart</h2>
        <p>
          The 200-day moving average chart is considered such a critically
          important trend indicator that the event of the {coinFullName} 50-day
          MA crossing to the downside of the 200-day MA is referred to as a{' '}
          {'"death cross", '}signaling an upcoming bear trend in{' '}
          {coinAbbreviation}.
        </p>
        <p>
          In like fashion, the {coinFullName} 50-day MA crossing over to the
          upside of the 200-day MA is sometimes called a {'"golden cross", '}
          referring to the fact that a {coinFullName} is considered
          {' "golden", '}
          or nearly sure to rise in price once that happens.
        </p>
      </section>
    </PostTemplate>
  );
}
